<template>
  <BlankPage
      :setTitle="setTitle"
  >
    <template
        v-slot:default="{
          title
        }"
    >
      <CardPart
          :icon="icon"
          :title="title"
      >
        <template
            v-slot:default
        >
          <v-row>
            <template
                v-if="$store.state.auth.User.division === 'admin'"
            >
              <template
                  v-if="$store.state.auth.User.type === 'master'"
              >
                <v-col
                    cols="12"
                >
                  <StatisticsVolunteersByPlaces/>
                </v-col>

                <v-col
                    cols="12"
                >
                  <StatisticsVolunteersByStatuses/>
                </v-col>

                <v-col
                    cols="12"
                >
                  <StatisticsVolunteersForPlaceByPositions/>
                </v-col>
              </template>
            </template>

            <template
                v-if="$store.state.auth.User.division === 'volunteer'"
            >
              <v-col
                  cols="12"
              >
                <Informations/>
              </v-col>

              <v-col
                  cols="12"
              >
                <Contacts/>
              </v-col>
            </template>
          </v-row>
        </template>
      </CardPart>
    </template>
  </BlankPage>
</template>

<script>
  import StatisticsVolunteersByPlaces from '@/components/Dashboard/Admin/StatisticsVolunteersByPlaces'
  import StatisticsVolunteersByStatuses from '@/components/Dashboard/Admin/StatisticsVolunteersByStatuses'
  import StatisticsVolunteersForPlaceByPositions from '@/components/Dashboard/Admin/StatisticsVolunteersForPlaceByPositions'
  import Informations from '@/components/Dashboard/Volunteer/Informations'
  import Contacts from '@/components/Dashboard/Volunteer/Contacts'

  import BlankPage from '@/components/_Part/_Page/Blank'

  import CardPart from '@/components/_Part/Card'

  export default {
    name: 'Dashboard',
    components: {
      StatisticsVolunteersByPlaces,
      StatisticsVolunteersByStatuses,
      StatisticsVolunteersForPlaceByPositions,
      Informations,
      Contacts,
      BlankPage,
      CardPart
    },
    data() {
      return {
        icon: 'mdi-home'
      }
    },
    methods: {
      setTitle() {
        return 'Nástenka'
      }
    }
  }
</script>
