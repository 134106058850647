<template>
  <!-- TODO -->
  <BlankPage
      :setTitle="setTitle"
  >
    <template
        v-slot:default="{
          title
        }"
    >
      <v-alert
          v-if="!$store.state.auth.User.hasSectionInterviews"
          type="info"
      >
        Pohovory sú pre Vás nedostupné.
      </v-alert>

      <CardPart
          v-else
          :icon="icon"
          :title="title"
      >
        <template
            v-slot:default
        >
          <v-row>
            <v-col
                cols="12"
            >
              <v-row>
                <v-col
                    cols="auto"
                >
                  <v-btn
                      :icon="true"
                      :outlined="true"
                      color="primary"
                      @click.stop.prevent="$refs.calendar.prev()"
                  >
                    <v-icon>
                      mdi-chevron-left
                    </v-icon>
                  </v-btn>
                </v-col>

                <v-spacer/>

                <v-col
                    cols="auto"
                >
                  <v-btn
                      :outlined="true"
                      color="primary"
                      @click.stop.prevent="viewMonth"
                  >
                    {{ calendar|moment('MM/YY') }}
                  </v-btn>
                </v-col>

                <v-spacer/>

                <v-col
                    cols="auto"
                >
                  <v-btn
                      :icon="true"
                      :outlined="true"
                      color="primary"
                      @click.stop.prevent="$refs.calendar.next()"
                  >
                    <v-icon>
                      mdi-chevron-right
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>

            <v-col
                cols="12"
            >
              <v-calendar
                  v-model="calendar"
                  ref="calendar"
                  locale="sk"
                  :type="type"
                  :weekdays="[1, 2, 3, 4, 5, 6, 0]"
                  :events="items"
                  event-start="from"
                  event-end="to"
                  :event-height="52"
                  :event-margin-bottom="2"
                  :interval-height="160"
                  @click:date="viewDay"
                  @change="fetchData"
              >
                <template
                    v-slot:event="{
                      event
                    }"
                >
                  <div
                      class="v-calendar-area"
                  >
                    {{ event.startPlain }} - {{ event.endPlain }}

                    <span class="float-right text-uppercase">{{ event.language }}</span>

                    <br/>

                    <v-btn
                        v-if="event.url"
                        :icon="true"
                        :outlined="true"
                        color="default"
                        :href="event.url"
                        target="_blank"
                    >
                      <v-icon>
                        mdi-video
                      </v-icon>
                    </v-btn>

                    <UpdateStatusFree
                        v-if="$store.state.auth.User.interviewUnsubscribeable && event.status === 'reserved'"
                        :id="event.id"
                        @onUpdateStatusFree="refreshData"
                    />

                    <UpdateStatusReserved
                        v-if="$store.state.auth.User.interviewSubscribeable && event.status === 'free'"
                        :id="event.id"
                        @onUpdateStatusReserved="refreshData"
                    />
                  </div>
                </template>
              </v-calendar>
            </v-col>
          </v-row>
        </template>
      </CardPart>
    </template>
  </BlankPage>
</template>

<script>
  import InterviewsAuth from '@/services/interviews-auth'

  import UpdateStatusFree from '@/components/InterviewsAuth/UpdateStatusFree'
  import UpdateStatusReserved from '@/components/InterviewsAuth/UpdateStatusReserved'

  import BlankPage from '@/components/_Part/_Page/Blank'

  import CardPart from '@/components/_Part/Card'

  export default {
    name: 'InterviewsAuth',
    components: {
      UpdateStatusFree,
      UpdateStatusReserved,
      BlankPage,
      CardPart
    },
    data() {
      return {
        icon: 'mdi-calendar',
        calendar: this.$moment().toISOString(),
        start: null,
        end: null,
        type: 'month',
        interviews: []
      }
    },
    computed: {
      items: function () {
        return this.interviews.map((_Interview) => {
          return {
            ..._Interview,
            name: _Interview.statusEnum,
            from: _Interview.datePlain + ' ' + _Interview.startPlain,
            to: _Interview.datePlain + ' ' + _Interview.endPlain,
            color: _Interview.statusColorEnum
          }
        })
      }
    },
    methods: {
      setTitle() {
        return 'Pohovory'
      },
      viewMonth() {
        this.type = 'month'
      },
      viewDay({date}) {
        this.calendar = date

        this.type = 'day'
      },
      refreshData() {
        this.fetchData({
          start: this.start,
          end: this.end
        })
      },
      fetchData({start, end}) {
        this.$store.dispatch('auth/check')

        this.start = start

        this.end = end

        InterviewsAuth
          .indexStartAndEnd({
            start: start.date,
            end: end.date
          })
          .then((response) => {
            this.interviews = response.data.data.interviews
          })
          .catch((error) => {
            this.$store.dispatch('layout/openSnackbar', {
              message: error.response.data.message
            })
          })
      }
    }
  }
</script>
