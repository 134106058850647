<template>
  <CreateSave
      :maxWidth="maxWidth"
      :setTitle="setTitle"
      :setSubtitle="setSubtitle"
      :setItemForm="setItemForm"
      :createData="createDateData"
      @onCreate="onCreateDate"
  >
    <template
        v-slot:default="{
          errors,
          ItemForm
        }"
    >
      <v-col
          cols="12"
          sm="6"
      >
        <v-text-field
            v-model="ItemForm.start"
            :error-messages="errors.start"
            :filled="true"
            :dense="true"
            hide-details="auto"
            label="Začiatok"
            type="time"
        />
      </v-col>

      <v-col
          cols="12"
          sm="6"
      >
        <v-text-field
            v-model="ItemForm.end"
            :error-messages="errors.end"
            :filled="true"
            :dense="true"
            hide-details="auto"
            label="Koniec"
            type="time"
        />
      </v-col>

      <v-col
          cols="12"
      >
        <AutocompleteRegisterIndexesIndexGroupValue
            v-model="ItemForm.language"
            :error-messages="errors.language"
            :filled="true"
            :dense="true"
            hide-details="auto"
            label="Jazyk"
            indexGroupValue="interview_language"
        />
      </v-col>
    </template>
  </CreateSave>
</template>

<script>
  import Interviews from '@/services/interviews'

  import CreateSave from '@/components/_Part/_Modal/_Save/Create'

  import AutocompleteRegisterIndexesIndexGroupValue from '@/components/Register/AutocompleteIndexesIndexGroupValue'

  export default {
    name: 'CreateDate',
    props: {
      date: {
        type: String,
        required: true
      }
    },
    emits: [
      'onCreateDate'
    ],
    components: {
      CreateSave,
      AutocompleteRegisterIndexesIndexGroupValue
    },
    data() {
      return {
        maxWidth: 500
      }
    },
    methods: {
      setTitle() {
        return 'Vytvoriť pohovor'
      },
      setSubtitle() {
        return this.$moment(this.date).format('DD.MM.YYYY')
      },
      setItemForm() {
        return {
          start: null,
          end: null,
          language: null
        }
      },
      createDateData(ItemForm) {
        return Interviews
          .createDate({
            date: this.date,
            data: ItemForm
          })
      },
      onCreateDate(response) {
        this.$emit('onCreateDate', response)
      }
    }
  }
</script>
